import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModules } from '@shared/shared.modules';
import {
  ConfigModule,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { IconModule, SpinnerModule } from '@spartacus/storefront';
import { EfaMiniCartComponent } from './components/efa-mini-cart/efa-mini-cart.component';
import { MiniCartDialogComponent } from './components/mini-cart-dialog/mini-cart-dialog.component';
import { MiniCartItemListComponent } from './components/mini-cart-item-list/mini-cart-item-list.component';
import { MiniCartItemComponent } from './components/mini-cart-item/mini-cart-item.component';
import { defaultMiniCartLayoutConfig } from './config/mini-cart-layout.config';

@NgModule({
  declarations: [
    EfaMiniCartComponent,
    MiniCartDialogComponent,
    MiniCartItemListComponent,
    MiniCartItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    SpinnerModule,
    SharedModules,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: EfaMiniCartComponent,
        },
      },
    }),
  ],
  providers: [provideConfig(defaultMiniCartLayoutConfig)],
})
export class MiniCartModule {}
