<div class="cx-page" *ngIf="model$ | async as model">
  <ng-container *ngIf="model.products?.length>0">
    <section class="cx-page-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12" *ngIf="viewMode$ | async as viewMode">
            <div class="cx-sorting top">
              <div class="row">
                <div class="col-12 col-lg-4 mr-auto">
                  <div class="form-group cx-sort-dropdown">
                    <cx-sorting
                      [sortOptions]="model.sorts"
                      (sortListEvent)="sortList($event)"
                      [selectedOption]="model.pagination.sort"
                      placeholder="{{
                      'productList.sortBy' | cxTranslate
                    }}"
                    ></cx-sorting>
                  </div>
                </div>
                <div *ngIf="!isInfiniteScroll" class="col-auto">
                  <div
                    class="cx-pagination"
                    aria-label="product search pagination"
                  >
                    <cx-pagination
                      [pagination]="model.pagination"
                      queryParam="currentPage"
                      [defaultPage]="0"
                    ></cx-pagination>
                  </div>
                </div>
                <div class="col-auto ml-auto ml-lg-0">
                  <cx-product-view
                    (modeChange)="setViewMode($event)"
                    [mode]="viewMode"
                  ></cx-product-view>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="banner$">
              <span class="col-12 banner-container">
                <ng-container *ngTemplateOutlet="componentTemplate; context: { item: banner$ | async }"></ng-container>
              </span>
            </div>
            <div class="cx-product-container">
              <ng-container>
                <!-- Product list when using pagination -->
                <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">

                  <ng-container *ngIf="viewMode === ViewModes.Grid">
                    <div class="row">
                      <app-efa-product-grid-item
                        *ngFor="let product of model?.products"
                        [product]="product"
                        class="col-12 col-sm-6 col-md-4"
                      ></app-efa-product-grid-item>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="viewMode === ViewModes.List">
                    <app-efa-product-list-item
                      *ngFor="let product of model?.products"
                      [product]="product"
                      class="cx-product-search-list"
                    ></app-efa-product-list-item>
                  </ng-container>
                </ng-container>


                <!-- Product list when using infinite scroll -->
                <ng-template #infiniteScroll>
                  <app-efa-product-scroll
                    [scrollConfig]="scrollConfig"
                    [model]="model"
                    [inputViewMode]="viewMode"
                  ></app-efa-product-scroll>
                </ng-template>
              </ng-container>
            </div>
            <div class="cx-sorting bottom">
              <div class="row">
                <div class="col-12 col-lg-4 mr-auto">
                  <div class="form-group cx-sort-dropdown">
                    <cx-sorting
                      [sortOptions]="model.sorts"
                      (sortListEvent)="sortList($event)"
                      [selectedOption]="model.pagination.sort"
                      placeholder="{{
                      'productList.sortBy' | cxTranslate
                    }}"
                    ></cx-sorting>
                  </div>
                </div>
                <div
                  *ngIf="!isInfiniteScroll"
                  class="col-auto"
                  aria-label="product search pagination"
                >
                  <div class="cx-pagination">
                    <cx-pagination
                      [pagination]="model.pagination"
                      queryParam="currentPage"
                      [defaultPage]="0"
                    ></cx-pagination>
                  </div>
                </div>
                <div class="col-auto ml-auto ml-lg-0">
                  <cx-product-view
                    (modeChange)="setViewMode($event)"
                    [mode]="viewMode"
                  ></cx-product-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
<ng-template #componentTemplate let-item="item">
  <ng-container [cxComponentWrapper]="{
      flexType: item.typeCode,
      typeCode: item.typeCode,
      uid: item?.uid,
    }">
  </ng-container>
</ng-template>