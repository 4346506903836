import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { ProductService, UserIdService, CmsProductCarouselComponent as model } from '@spartacus/core';
import { CmsComponentData, ProductCarouselComponent } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CartParametersService } from '../../../cart-administration/services/cart-parameters.service';
import { PermissionCheckService } from '../../../user-profiles/service/permission-check.service';
import { PriceResponseItem } from '../../model/product-prices.model';
import { ProductPricesService } from '../../service/product-prices.service';

@Component({
  selector: 'app-efa-product-carousel',
  templateUrl: './efa-product-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class EfaProductCarouselComponent extends ProductCarouselComponent implements OnInit {

  prices$: Observable<PriceResponseItem[]>;

  displayCustomerPrices$: Observable<boolean> = this.permissionCheckService.displayCustomerPrices();

  readonly pdpCallSource: PDP_CALL_SOURCE = PDP_CALL_SOURCE.CAROUSEL;

  constructor(componentData: CmsComponentData<model>,
              productService: ProductService,
              private productPricesService: ProductPricesService,
              private cartParametersService: CartParametersService,
              private userIdService: UserIdService,
              private permissionCheckService: PermissionCheckService) {
    super(componentData, productService);
  }

  ngOnInit(): void {
    this.prices$ = combineLatest([this.componentData.data$, this.cartParametersService.getCurrentOrderType(),
      this.userIdService.getUserId()])
      .pipe(
        filter(([data, orderType, userId]) => orderType !== undefined),
        map(([data, orderType, userId]) => {
          const productCodes = data.productCodes?.trim().split(' ') ?? [];
          return {productCodes, orderType, userId};
        }),
        filter(params => params.productCodes.length > 0),
        switchMap(params =>
          this.productPricesService.loadAndGetPrices(params.productCodes, params.userId, params.orderType, false)),
        filter(prices => prices?.length > 0));

  }

  getCustomerPriceFormattedValue(prices: PriceResponseItem[], code: string): string {
    return prices?.find(item => item.productCode === code)?.customerPrice?.formattedValue;
  }

  getCustomerPriceValue(prices: PriceResponseItem[], code: string): number {
    return prices?.find(item => item.productCode === code)?.customerPrice?.value;
  }

  getListPriceFormattedValue(prices: PriceResponseItem[], code: string): string {
    return prices?.find(item => item.productCode === code)?.listPrice?.formattedValue;
  }

  getListPriceValue(prices: PriceResponseItem[], code: string): number {
    return prices?.find(item => item.productCode === code)?.listPrice?.value;
  }
}
