<ng-container *ngIf="displayCustomerPrices$ | async">
  <h4>{{ 'orderCost.orderSummary' | cxTranslate }}</h4>

  <div class="cx-summary-partials" *ngIf="cart">
    <div class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.subtotal' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.subTotal?.formattedValue }}
      </div>
    </div>
    <div *ngIf="!cart.fromConfigurator && (cart.deliveryCostPending?.value === 0 || (cart.deliveryCostPending?.value > 0 && cart.orderValueMissing?.value === 0))" class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{
        'orderCost.shipping'| cxTranslate
        }}
      </div>
      <div class="col-6 cx-summary-amount">
        <ng-container *ngIf="cart.deliveryCostPending?.value === 0 || cart.orderValueMissing?.value > 0; else zeroShippingCost">
          {{
            cart.deliveryCost?.formattedValue
          }}
        </ng-container>
        <ng-template #zeroShippingCost>
          {{
            'orderCost.zero'| cxTranslate
          }}
        </ng-template>
      </div>
    </div>

    <div *ngIf="!cart.fromConfigurator && cart.deliveryCostPending?.value > 0 && cart.orderValueMissing?.value > 0" class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.shippingPending' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.deliveryCostPending?.formattedValue }}
      </div>
    </div>

    <div *ngIf="!cart.fromConfigurator" class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.salesTax' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalTax?.formattedValue }}
      </div>
    </div>

    <div class="cx-summary-row" *ngIf="cart.totalExchangeTax && !cart.fromConfigurator">
      <div class="col-6 cx-summary-label">
        {{ 'cartAdministration.orderCost.atTax' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalExchangeTax?.formattedValue }}
      </div>
    </div>
    <div class="cx-summary-row cx-summary-total">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.total' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalPriceWithTax?.formattedValue }}
      </div>
    </div>
  </div>
</ng-container>
