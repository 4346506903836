import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModules } from '@shared/shared.modules';
import {
  ConfigModule,
  Converter,
  I18nConfig,
  I18nModule,
  Product,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { AddToCartModule } from '../add-to-cart/add-to-cart.module';
import { EfaProductCarouselComponent } from './components/efa-product-carousel/efa-product-carousel.component';
import { EfaProductReferencesComponent } from './components/efa-product-references/efa-product-references.component';
import { ProductPriceRequest } from './model/product-prices.model';
import { OccModule } from './occ/occ.module';
import { ProductPricesSerializer } from './occ/product-prices.serializer';
import { ProductPricesEffects } from './store/product-prices.effects';
import {
  productPricesFeatureKey,
  reducer,
} from './store/product-prices.reducer';
import {
  productPricesTranslationChunksConfig,
  productPricesTranslations,
} from './translations/product-prices.translations';

export const PRODUCT_PRICES_SERIALIZER = new InjectionToken<
  Converter<Product[], ProductPriceRequest>
>('ProductPricesSerializer');

@NgModule({
  imports: [
    CommonModule,
    SharedModules,
    UrlModule,
    RouterModule,
    I18nModule,
    CarouselModule,
    MediaModule,
    AddToCartModule,
    StoreModule.forFeature(productPricesFeatureKey, reducer),
    EffectsModule.forFeature([ProductPricesEffects]),
    OccModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            productPrices:
              '/users/${userId}/retrieveProductPrices?fields=DEFAULT',
          },
        },
      },
      cmsComponents: {
        ProductReferencesComponent: {
          component: EfaProductReferencesComponent,
        },
        ProductCarouselComponent: {
          component: EfaProductCarouselComponent,
        },
        AlreadyOpenedProductsCarouselComponent: {
          component: EfaProductCarouselComponent,
        },
      },
    }),
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: productPricesTranslations,
        chunks: productPricesTranslationChunksConfig,
      },
    }),
    {
      provide: PRODUCT_PRICES_SERIALIZER,
      useExisting: ProductPricesSerializer,
      multi: true,
    },
  ],
  declarations: [EfaProductReferencesComponent, EfaProductCarouselComponent],
  exports: [EfaProductReferencesComponent, EfaProductCarouselComponent],
})
export class ProductPricesModule {}
