<ngb-carousel *ngIf="getItems() | async as items$$" [interval]="timeout">
    <ng-container *ngFor="let item$ of items$$">
        <ng-template ngbSlide *ngIf="item$ | async as item">
            <ng-container *ngTemplateOutlet="template; context: { item }"></ng-container>
        </ng-template>
    </ng-container>
</ngb-carousel>
<ng-template #template let-item="item">
    <ng-container [cxComponentWrapper]="{
        flexType: item.typeCode,
        typeCode: item.typeCode,
        uid: item?.uid
      }">
    </ng-container>
</ng-template>