import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import {
  MiniCartComponent,
  MiniCartComponentService,
} from '@spartacus/cart/base/components/mini-cart';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { AuthService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PermissionCheckService } from '../../../user-profiles/service/permission-check.service';
import { MiniCartDialogComponent } from '../mini-cart-dialog/mini-cart-dialog.component';

@Component({
  selector: 'app-efa-mini-cart',
  templateUrl: './efa-mini-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaMiniCartComponent
  extends MiniCartComponent
  implements OnDestroy
{
  authService: AuthService;
  miniCartDialogOpen$: Subject<boolean> = new BehaviorSubject(false);

  private subscription: Subscription = new Subscription();

  constructor(
    protected miniCartComponentService: MiniCartComponentService,
    protected permissionCheckService: PermissionCheckService,
    protected activeCartService: ActiveCartFacade,
    authService: AuthService,
    private launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) {
    super(miniCartComponentService);
    this.authService = authService;
  }

  displayCustomerPrices$: Observable<boolean> =
    this.permissionCheckService.displayCustomerPrices();
  
  subTotal$: Observable<string> = this.activeCartService.getActive().pipe(
    map((cart) => (cart && cart.subTotal ? cart.subTotal.formattedValue : ''))
  );
  count$: Observable<number> = this.activeCartService.getActive().pipe(
    map((cart) =>
      !!cart && cart.entries !== undefined
        ? Array.from(
            cart.entries?.filter((e) => !e.subEntry),
            () => 1
          ).reduce((acc, curr) => acc + curr, 0)
        : 0
    )
  );

  openDialog(): void {
    const componentRef$: Observable<
      ComponentRef<MiniCartDialogComponent>
    > | void = this.launchDialogService.launch(
      LAUNCH_CALLER.MINI_CART,
      this.vcr
    );

    if (componentRef$) {
      this.miniCartDialogOpen$.next(true);
      this.subscription.add(
        combineLatest([componentRef$, this.launchDialogService.dialogClose])
          .pipe(
            filter(([, close]) => Boolean(close)),
            tap(([comp]) => {
              this.launchDialogService.clear(LAUNCH_CALLER.MINI_CART);
              comp?.destroy();
              this.miniCartDialogOpen$.next(false);
            })
          )
          .subscribe()
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
