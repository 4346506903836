import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { OrderLimitExceededConfirmationDialogComponent } from '../components/order-limit-exceeded-confirmation-dialog/order-limit-exceeded-confirmation-dialog.component';

export const OrderLimitExceededConfirmationLayoutConfig: LayoutConfig = {
  launch: {
    ORDER_LIMIT_EXCEEDED: {
      inlineRoot: true,
      component: OrderLimitExceededConfirmationDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
      multi: false,
    },
  },
};
