<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <ng-container *ngIf="displayCustomerPrices$ | async">
      <div class="d-flex flex-column flex-md-row justify-content-end" [ngClass]="{'mb-3' : !cart.fromConfigurator}">
        <div class="d-flex col-12 col-md-8 p-0 flex-column justify-content-end">
          <app-order-shipping-cost-notice [cart]="cart"></app-order-shipping-cost-notice>
        </div>
        <div class="d-flex flex-column col-12 col-md-4 p-0">
          <app-efa-order-summary [cart]="cart"></app-efa-order-summary>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-3" *ngIf="(isCartContainingEntriesWithExchangeTaxRequired$ | async) && cart.totalExchangeTax && !cart.fromConfigurator; else configuratorTaxNotice">
        <div class="col-12 col-md-4 p-0">
          <p class="c-cart-tax-notice">
            <sup>*</sup> {{ 'cartAdministration.orderCost.noticeATTax' | cxTranslate }}
          </p>
        </div>
      </div>
      <ng-template #configuratorTaxNotice>
        <div class="d-flex justify-content-end c-configurator-cart-tax-notice-container" *ngIf="cart.fromConfigurator">
          <div class="col-12 col-md-4 p-0">
            <p class="c-configurator-cart-tax-notice">
              <sup>*</sup> {{ 'cartAdministration.orderCost.noticeConfiguratorTaxAndDeliveryCosts' | cxTranslate }}
            </p>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <div class="d-flex justify-content-end">
      <ng-container *ngIf="canPlaceOrders$ | async">
        <ng-container *ngIf="cart.skipAutomaticCalculation && !cart.calculatedForCheckout && !cart.fromConfigurator; else calculate">
          <div class="col-12 col-md-4 p-0">
            <button *ngIf="entries.length"
                    class="btn btn-primary btn-block"
                    type="button" (click)="calculateCart()">
              {{ 'cartAdministration.cart.calculate' | cxTranslate }}
            </button>
          </div>
        </ng-container>
        <ng-template #calculate>
          <div class="col-12 col-md-4 p-0">
            <button [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
                    *ngIf="entries.length"
                    class="btn btn-primary btn-block"
                    type="button" (click)="publishFieldMarks()">
              {{ 'cartDetails.proceedToCheckout' | cxTranslate }}
            </button>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

