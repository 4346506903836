<div
  class="cx-modal-container product-search-modal"
  [cxFocus]="focusConfig"
  (esc)="closeModal()"
>

  <div class="cx-modal-content">

    <div class="cx-dialog-header modal-header">
      <h5></h5>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <div class="cx-dialog-content modal-body">
      <div class="outer-container">
        <table class="table table--responsive">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">{{ 'cartAdministration.cartProductSearchResult.article' | cxTranslate }}</th>
            <th scope="col">{{ 'cartAdministration.cartProductSearchResult.brand' | cxTranslate }}</th>
            <th scope="col">{{ 'cartAdministration.cartProductSearchResult.quantity' | cxTranslate }}</th>
            <th scope="col">{{ 'cartAdministration.cartProductSearchResult.warehouse' | cxTranslate }}</th>
            <th scope="col">{{ 'cartAdministration.cartProductSearchResult.price' | cxTranslate }}</th>
          </tr>
          </thead>
          <tbody *ngIf="productSearchResults?.length">
          <ng-container *ngFor="let result of productSearchResults">
            <ng-container *ngFor="let item of result.resultItems; let i = index">
              <tr [ngClass]="{'u-color-light': result.alternative === true, 'has-message': item.message != null}">
                <td [ngClass]="{'text-right': result.alternative === true}">
                  <div class="form-check" *ngIf="i==0 && item.message == null">
                    <input class="form-check-input"
                          name="item.materialNumber"
                          type="checkbox"
                          [value]="item.materialNumber"
                          (change)="changeProductSelection(result.id, $event)"/>
                  </div>
                </td>
                <td attr.data-label="{{ 'cartAdministration.cartProductSearchResult.article' | cxTranslate }}">
                  <div class="text-uppercase pb-2 u-font-bold ">{{ item.materialName }}</div>
                  <div>{{ 'cartAdministration.cartProductSearchResult.articleId' | cxTranslate :
                    {oemNumber: item.oemNumber} }}</div>
                </td>
                <td attr.data-label="{{ 'cartAdministration.cartProductSearchResult.brand' | cxTranslate }}">
                  {{ item.manufacturerName }}
                </td>
                <td attr.data-label="{{ 'cartAdministration.cartProductSearchResult.quantity' | cxTranslate }}">
                  <ng-container *ngFor="let del of item.deliveryItems">
                    <div>{{ del.availableQuantity }}</div>
                  </ng-container>
                </td>
                <td attr.data-label="{{ 'cartAdministration.cartProductSearchResult.warehouse' | cxTranslate }}">
                  <ng-container *ngFor="let del of item.deliveryItems">
                    <div class="text-uppercase">{{ del.warehouseName }}</div>
                  </ng-container>
                </td>
                <td attr.data-label="{{ 'cartAdministration.cartProductSearchResult.price' | cxTranslate }}">
                  {{ item.customerPricePerUnit.formattedValue }}
                </td>
              </tr>
              <tr *ngIf="item.message" class="message-row">
                <td></td>
                <td colspan="5">
                  {{ item.message.text }}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="cx-dialog-footer modal-footer">
      <button class="btn btn-secondary" type="submit"
              (click)="submitData()">
        {{ 'cartAdministration.modalDialog.modalDialogBtn' | cxTranslate }}
      </button>
    </div>

  </div>
</div>