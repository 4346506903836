import { Component } from '@angular/core';
import { EfaCartItemComponent } from '@shared/components/efa-cart-item/efa-cart-item.component';
import { ICON_TYPE } from '../../model/mini-cart-enum.model';

@Component({
  selector: 'app-mini-cart-item',
  templateUrl: './mini-cart-item.component.html'
})
export class MiniCartItemComponent extends EfaCartItemComponent {
  readonly iconType = ICON_TYPE;
}
