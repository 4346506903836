import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {B2BUser, GlobalMessageType, TranslationService} from '@spartacus/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';

import {
  CONSIGNMENT_INFO_ALLOWED_MAX_LENGTH,
  CONSIGNMENT_INFO_ALLOWED_PATTERN,
  Message
} from '@shared/models/shared.model';
import {CartAndCheckoutLoadingService} from '@shared/services/cart-and-checkout-loading.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {CartModification, DeliveryMode} from '@spartacus/cart/base/root';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  skipWhile,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {CartImportExportService} from '../../../import-export/services/cart-import-export-service';
import {ICON_TYPE, ProductCategory} from '../../model/cart-administration-enum.model';
import {
  CartProductSearchRequest,
  CartProductSearchResult,
  CartProductSearchResultItem,
  Manufacturer,
  OrderType
} from '../../model/cart-administration.model';
import {B2BUserAccountService} from '../../services/b2-b-user-account.service';
import {CartParametersService} from '../../services/cart-parameters.service';
import {CartProductSearchService} from '../../services/cart-product-search.service';
import {MultiAddToCartService} from '../../services/multi-add-to-cart.service';
import {
  getCartProductSearchResultsNotPurchasableReason,
  isCartProductSearchResultPurchasable,
  PRUEFARTIKEL
} from '../../shared/utils/cart-product-search-result-utils';
import { getGlobalMessageType } from '@shared/utils/message-utils';

const MANUFACTURER_NAMES_SEPARATOR = ', ';
const ARTICLE_NUMBER_ALLOWED_PATTERN = '[A-Za-z0-9/|\\s|\\h|+|\\-|#|\\.]*';



@Component({
  selector: 'app-cart-product-search',
  templateUrl: './cart-product-search.component.html',
})
export class CartProductSearchComponent implements OnInit, OnDestroy {
  @ViewChild('oemNumberInput') oemNumberInput: ElementRef;

  private subscription: Subscription = new Subscription();
  private isAdding$: Observable<boolean>;
  private isShippingTypeLoading$: Subject<boolean> = new ReplaySubject();
  private isOrderTypeLoading$: Subject<boolean> = new ReplaySubject();
  public isExternalPortalUser$ = new BehaviorSubject<boolean>(false);
  readonly consignmentInfoAllowedMaxLength: number = CONSIGNMENT_INFO_ALLOWED_MAX_LENGTH;
  displaySearchForAlternativeArticles = false;
  iconTypes = ICON_TYPE;
  defaultManufacturerNames: string[] = [];
  currentOrderType: OrderType = {};
  currentShippingType: DeliveryMode = {};
  selectedManufacturerNames: string[];
  selectedManufacturerCodes: string[];
  cartProductSearchForm: UntypedFormGroup = this.fb.group({
    productCategory: [ProductCategory.SPARE_PARTS, [Validators.required]],
    manufacturerCodes: {value: this.defaultManufacturerNames, disabled: true},
    oemNumber: ['', [Validators.required, Validators.pattern(ARTICLE_NUMBER_ALLOWED_PATTERN),]],
    quantity: [1, [Validators.required]],
    consignmentInfo: ['', [Validators.pattern(CONSIGNMENT_INFO_ALLOWED_PATTERN)]],
    searchForAlternativeArticles: false
  });
  importRunning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCartLoading$: Observable<boolean>;
  hideAll: boolean;
  isOrderTypeManufacturersWhiteList: boolean;
  manufacturerSelectionDisabled = false;


  constructor(
    protected cartParametersService: CartParametersService,
    protected cartProductSearchService: CartProductSearchService,
    private b2bUserAccountService: B2BUserAccountService,
    private multiAddToCartService: MultiAddToCartService,
    private globalMessageService: EfaGlobalMessageService,
    private fb: UntypedFormBuilder,
    private importExportService: CartImportExportService,
    private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
    private translation: TranslationService,
    private userAccountFacade: UserAccountFacade,
    private launchDialogService: LaunchDialogService
  ) {
  }

  ngOnInit(): void {
    this.isAdding$ = this.multiAddToCartService.isAdding();
    this.isCartLoading$ = this.cartAndCheckoutLoadingService.getCombinedLoading();
    this.setCurrentOrderType();
    this.setCurrentShippingType();

    this.subscription.add(
      this.b2bUserAccountService.getB2bOrgUnitLoaded()
      .subscribe(() => {
        this.b2bUserAccountService.setSelectedManufacturer(this.b2bUserAccountService.defaultManufacturers);
        this.defaultManufacturerNames = this.b2bUserAccountService.defaultManufacturerNames;
        this.setSelectedManufacturerNamesCodes();
      }));
    this.displaySearchForAlternativeArticles = this.b2bUserAccountService.alternativesSearchAllowed;


    this.listenToProductSearchResult();
    this.importExportService.status$.pipe(map(status => status.searching)).subscribe((searching) => {
      this.importRunning$.next(searching);
    });
    this.detectErroneousCartModifications();
    this.hideAll = this.b2bUserAccountService.cart && this.b2bUserAccountService.cart.fromConfigurator;

    this.subscription.add(this.cartParametersService.getCurrentProductCategory()
    .pipe(
      filter(Boolean),
      distinctUntilChanged((a, b) => a === b))
    .subscribe(v => {
      this.cartProductSearchForm.patchValue({productCategory: v});
      this.manufacturerSelectionDisabled = (v !== ProductCategory.SPARE_PARTS);
      if (this.manufacturerSelectionDisabled) {
        this.cartProductSearchForm.get('manufacturerCodes').patchValue('');
      }
    }));
    this.subscription.add(this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      if (b2bUser?.portalUser) {
        this.isExternalPortalUser$.next(true);
      }
    }));
    this.subscription.add(this.userAccountFacade.get().subscribe((b2bUser: B2BUser) => {
      if (b2bUser?.portalUser) {
        this.isExternalPortalUser$.next(true)
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(): void {

    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_WARNING);

    if (this.cartProductSearchForm.invalid) {
      this.cartProductSearchForm.markAllAsTouched();
      return;
    }

    this.cartAndCheckoutLoadingService.setSearchingOrAdding(true);
    const cartProductSearchRequest: CartProductSearchRequest = {
      productCategory: this.cartProductSearchForm.value.productCategory,
      manufacturerCodes: ProductCategory.SPARE_PARTS === this.cartProductSearchForm.value.productCategory
        ? this.selectedManufacturerCodes
        : [],
      oemNumber: this.cartProductSearchForm.value.oemNumber.trim().toUpperCase(),
      quantity: this.cartProductSearchForm.value.quantity,
      alternativeArticles: this.cartProductSearchForm.value.searchForAlternativeArticles,
      orderTypeId: this.currentOrderType.id,
      shippingTypeId: this.currentShippingType.code,
    };

    let requestedArticle;
    if (this.b2bUserAccountService.cart && this.b2bUserAccountService.cart.entries) {
      requestedArticle = this.b2bUserAccountService.cart.entries.find(
        entry => entry.product?.manufacturerAID === cartProductSearchRequest.oemNumber && entry.parent);
    }
    if (requestedArticle !== undefined) {
      this.cartAndCheckoutLoadingService.setSearchingOrAdding(false);
      this.globalMessageService.add(
        {
          key: 'cartAdministration.globalMessage.articleAlreadyInCart',
          params: {requestedArticle: cartProductSearchRequest.oemNumber}
        },
        GlobalMessageType.MSG_TYPE_WARNING
      );
    } else {
      this.cartProductSearchService.searchProducts(cartProductSearchRequest);
    }
  }

  onOpenModal(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.MANUFACTURERS_SELECTION, undefined, undefined);
  }

  setCurrentOrderType(): void {
    this.subscription.add(
      this.cartParametersService.getCurrentOrderType().subscribe((orderType: OrderType) => {
        this.currentOrderType = orderType;
        if (orderType && orderType.manufacturers !== null && orderType.manufacturers?.length > 0) {
          if (orderType.manufacturersIncluded) {
            this.selectedManufacturerCodes = [];
            const selectedManufacturerNames: string[] = [];
            const selectedManufacturers: Manufacturer[] = [];
            orderType.manufacturers?.forEach((manufacturer: Manufacturer) => {
                this.selectedManufacturerCodes.push(manufacturer.id);
                selectedManufacturerNames.push(manufacturer.name);
                selectedManufacturers.push(manufacturer);
              }
            );
            this.isOrderTypeManufacturersWhiteList = true;
            this.cartProductSearchForm.patchValue({
              manufacturerCodes: selectedManufacturerNames.join(MANUFACTURER_NAMES_SEPARATOR),
            });
            this.b2bUserAccountService.setSelectedManufacturer(selectedManufacturers);
          } else {
            this.isOrderTypeManufacturersWhiteList = false;
            this.b2bUserAccountService.setSelectedManufacturer(this.b2bUserAccountService.defaultManufacturers);
          }

          this.isOrderTypeLoading$.next(false);
        }
      })
    );
  }

  setCurrentShippingType(): void {
    this.subscription.add(
      this.cartParametersService.getCurrentShippingType().subscribe((shippingType: DeliveryMode) => {
        this.currentShippingType = shippingType;
        if (shippingType) {
          this.isShippingTypeLoading$.next(false);
        }
      })
    );
  }

  setSelectedManufacturerNamesCodes(): void {
    this.subscription.add(
      combineLatest([this.b2bUserAccountService.getSelectedManufacturers(), this.cartParametersService.getCurrentOrderType()])
      .pipe(
        filter(([_, ot]) => ot !== undefined))
      .subscribe(([manufacturers, orderType]) => {
        this.selectedManufacturerNames = [];
        this.selectedManufacturerCodes = [];
        if (manufacturers.length) {
          let filteredManufacturers: Manufacturer[];

          if (orderType.manufacturers != null && orderType.manufacturers.length > 0) {
            if (orderType.manufacturersIncluded) {
              filteredManufacturers = this.b2bUserAccountService.visibleManufacturers.filter(
                (manufacturer: Manufacturer) =>
                  orderType.manufacturers?.findIndex(
                    (orderTypeManufacturer: Manufacturer) =>
                      orderTypeManufacturer.id === manufacturer.id
                  ) > -1
              );
            } else {
              filteredManufacturers = manufacturers.filter(
                (manufacturer: Manufacturer) =>
                  orderType.manufacturers?.findIndex(
                    (orderTypeManufacturer: Manufacturer) =>
                      orderTypeManufacturer.id === manufacturer.id
                  ) < 0
              );
            }
          } else {
            filteredManufacturers = manufacturers;
          }

          filteredManufacturers.forEach((manufacturer: Manufacturer) => {
              this.selectedManufacturerNames.push(manufacturer.name);
              this.selectedManufacturerCodes.push(manufacturer.id);
            }
          );
        } else if (orderType.manufacturersIncluded && orderType.manufacturers != null && orderType.manufacturers.length > 0) {
          orderType.manufacturers.forEach((manufacturer: Manufacturer) => {
              this.selectedManufacturerNames.push(manufacturer.name);
              this.selectedManufacturerCodes.push(manufacturer.id);
            }
          );
        }

        this.cartProductSearchForm.patchValue({
          manufacturerCodes: this.selectedManufacturerNames.join(MANUFACTURER_NAMES_SEPARATOR),
        });
      })
    )
    ;
  }

  listenToProductSearchResult(): void {
    this.subscription.add(
      this.cartProductSearchService.getSearchResponse().pipe(
        withLatestFrom(this.importRunning$),
        filter(([results, running,]) => results !== undefined && !running),
      ).subscribe(([response,]) => {
        const results = response.results;
        if (results.length === 0) {
          this.translation.translate('cartAdministration.cartProductSearchForm.productCategories.'
            + this.camelize(this.cartProductSearchForm.get('productCategory')?.value)).pipe(first()).subscribe((categoryText: string) => {
            this.cartAndCheckoutLoadingService.setSearchingOrAdding(false);
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(
              {
                key: 'cartAdministration.globalMessage.productSearchNoResult',
                params: {
                  articleNumber: this.cartProductSearchForm.get('oemNumber')?.value,
                  category: categoryText
                }
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
        } else {
          const purchasableResults: CartProductSearchResult[] =
            results.filter((result: CartProductSearchResult) => isCartProductSearchResultPurchasable(result));
          if (purchasableResults?.length === 1) {
            const firstPurchasableResultItemWithErrorTag: CartProductSearchResultItem = this.getFirstResultItemWithErrorTag(purchasableResults);
            if (firstPurchasableResultItemWithErrorTag == null) {
              this.cartAndCheckoutLoadingService.setSearchingOrAdding(true);
              this.addEntries(
                purchasableResults
              );
            } else {
              this.cartAndCheckoutLoadingService.setSearchingOrAdding(false);
              const message: Message = firstPurchasableResultItemWithErrorTag.message;
              this.globalMessageService.add(message.text, getGlobalMessageType(message.type));
            }
          } else if (purchasableResults?.length > 1) {
            this.cartAndCheckoutLoadingService.setSearchingOrAdding(false);
            this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_PRODUCT_SEARCH_RESULT, undefined, purchasableResults);
            this.triggerProductSearchResultModal();
          } else {
            this.cartAndCheckoutLoadingService.setSearchingOrAdding(false);
            this.displayWarningMessageForSearchResultsSalesStatus(results);
          }
        }
        this.cartProductSearchService.reset();
        this.oemNumberInput.nativeElement.focus();
      })
    );
  }

  private addEntries(results): void {
    this.cartAndCheckoutLoadingService.setSearchingOrAdding(true);
    this.multiAddToCartService.addEntries(
      results,
      this.cartProductSearchForm.value.oemNumber?.trim().toUpperCase(),
      this.cartProductSearchForm.value.consignmentInfo
    );

    this.multiAddToCartService.isAdding()
    .pipe(
      skipWhile(v => !v))
    .subscribe((v) => {
      this.cartAndCheckoutLoadingService.setSearchingOrAdding(v);
    });
  }

  private getFirstResultItemWithErrorTag(cartProductSearchResults: CartProductSearchResult[]): CartProductSearchResultItem {
    for (const cartProductSearchResult of cartProductSearchResults) {
      for (const cartProductSearchResultItem of cartProductSearchResult.resultItems) {
        if (cartProductSearchResultItem.message?.text != null) {
          return cartProductSearchResultItem;
        }
      }
    }

    return undefined;
  }

  triggerProductSearchResultModal(): void {
    const forceComplete$: Subject<void> = new Subject();
    this.launchDialogService.dialogClose.pipe(takeUntil(forceComplete$)).subscribe((results: CartProductSearchResult[] | string) => {
      if (results === 'closed') {
        forceComplete$.next();
      } else if (!!results) {
        this.addEntries(results);
        forceComplete$.next();
      }
    });
  }

  private detectErroneousCartModifications(): void {
    this.subscription.add(this.multiAddToCartService
    .getModifications()
    .pipe(
      withLatestFrom(this.importRunning$, this.isAdding$),
      filter(
        ([modifications, running, isAddingToCart]) => modifications != null && !running && !isAddingToCart
      )
    )
    .subscribe(([modifications]) => {
      const erroneousCartModification =
        modifications.cartModifications.find(
          (modification: CartModification) =>
            modification.quantityAdded === 0 ||
            modification.statusCode === 'error'
        );
      if (erroneousCartModification != null) {
        this.globalMessageService.add(
          {
            key: 'cartAdministration.globalMessage.multiAddToCartFailure',
          },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      } else {
        this.cartProductSearchForm.get('oemNumber').reset('');
        this.cartProductSearchForm.get('quantity').reset(1);
        this.cartProductSearchForm.get('consignmentInfo').reset('');
      }
    }));
  }

  private displayWarningMessageForSearchResultsSalesStatus(results: CartProductSearchResult[]): void {
    const reason = getCartProductSearchResultsNotPurchasableReason(results);
    let oemNumber = results[0]?.resultItems[0]?.oemNumber;
    if (oemNumber === PRUEFARTIKEL) {
      oemNumber = this.cartProductSearchForm.get('oemNumber')?.value;
    }
    this.globalMessageService.add({
        key: 'cartAdministration.globalMessage.productSearchNotPurchasableArticle.reason',
        params: {
          oemNumber,
          context: reason,
        }
      },
      GlobalMessageType.MSG_TYPE_WARNING
    );
  }

  testAndUntouch(control: AbstractControl): void {
    // prevent validation message if inout looses focus without being changed before
    if (control.value === undefined || control.value.length === 0) {
      control.markAsUntouched();
    }
  }

  productCategoryChanged(event: any): void {
    this.cartParametersService.setCurrentProductCategory(this.cartProductSearchForm.get('productCategory').value);
  }


  camelize(str: string): string {
    if (str === undefined) {
      return '';
    }
    return str.toLowerCase().replace('_', ' ').replace(/\W+(.)/g, (match, chr) => {
      return chr.toUpperCase();
    });
  }
}
