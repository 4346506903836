import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigModule } from '@spartacus/core';
import {
  ImageLoadingStrategy,
  MediaConfig,
  MediaModule,
  PageComponentModule,
} from '@spartacus/storefront';
import { EfaBannerCarouselComponent } from './components/efa-banner-carousel/efa-banner-carousel.component';
import { EfaBannerComponent } from './components/efa-banner/efa-banner.component';
import { EfaGenericLinkComponent } from './components/efa-generic-link/efa-generic-link.component';
import { EfaMediaComponent } from './components/efa-media/efa-media.component';

@NgModule({
  declarations: [
    EfaBannerComponent,
    EfaBannerCarouselComponent,
    EfaGenericLinkComponent,
    EfaMediaComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    PageComponentModule,
    NgbModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SimpleResponsiveBannerComponent: {
          component: EfaBannerComponent,
        },
        BannerComponent: {
          component: EfaBannerComponent,
        },
        SimpleBannerComponent: {
          component: EfaBannerComponent,
        },
        RotatingImagesComponent: {
          component: EfaBannerCarouselComponent,
        },
      },
    }),
    ConfigModule.withConfig({
      imageLoadingStrategy: ImageLoadingStrategy.LAZY,
    } as MediaConfig),
  ],

  exports: [EfaBannerComponent],
})
export class BannerModule {}
