<ng-container *ngIf="tileItem as item">
  <div *ngIf="item.active === 'false'" class="inactiveItemContainer">
    <div class="image-box">
      <img src="../../../../../../assets/icons/Maintenance.png" alt="">
    </div>
    <div class="inactiv-item-text-wrapper">
      <div *ngIf="(item.inActiveTitle | json) != '{}'" class="text-title">{{ item.inActiveTitle }}</div>
      <div *ngIf="(item.inActiveText | json) != '{}'" class="text-description">{{item.inActiveText}}</div>
    </div>
  </div>
  <div
    class="tile-item-container {{item.buttonStyle?.includes('btn-link') ? 'custom-link-style':''}} {{ item.hideBorder === 'true' ? 'no-border' : '' }} {{ item.styleClasses ? item.styleClasses : ''}}"
    [style.color]="item.color ?? 'inherit'"
    [style.backgroundColor]="item.backgroundColor ?? 'inherit'"
    [ngStyle]="{'filter':(item.active === 'false') ? 'opacity(20%) blur(12px)' : 'none' }"
  >
    <div class="tile-item-image {{ item.styleClasses }}">
      <cx-media
        [style.--invert-image]="item.invertImage === 'true' ? 'invert(1)' : 'invert(0)'"
        [container]="item.medias"
      ></cx-media>
    </div>

    <div class="tile-item-text-wrapper ">
      <div class="tile-item-title {{item.subtitle ? 'title-has-subtitle': ''}} {{item.buttonStyle?.includes('btn-link') ? 'partslink-24-Zugang-link-title' : ''}}">
        {{ item.title }}
      </div>

      <div *ngIf="item.subtitle" class="tile-item-subtitle">
        {{ item.subtitle }}
      </div>
      <div *ngIf="!item.buttonStyle?.includes('btn-link')" class="tile-item-description">
        {{ item.description }}
      </div>
    </div>

    <div class="tile-item-link" *ngIf="item.url.length; else tileItemContent">
      <app-tile-link
        [url]="item.url"
        [overridenLocalUrl]="overridenLocalUrl"
        [target]="target"
        [classes]="[item.buttonStyle, item.buttonStyle?.includes('btn-primary')||item.buttonStyle?.includes('btn-secondary') ? 'btn btn-block':'', item.buttonStyle ?? 'btn-primary w-75']"
      >
        <cx-icon *ngIf="item.buttonIconId" [type]="item.buttonIconId"></cx-icon>
        {{ item.buttonText }}
      </app-tile-link>
    </div>

    <ng-template #tileItemContent>
      <div *ngIf="item.buttonText" class="tile-item-content">
        <p>
          {{ item.buttonText }}
        </p>
      </div>
    </ng-template>
  </div>
</ng-container>
