import { MessageTypeEnum } from '@shared/models/shared.model';
import { GlobalMessageType } from '@spartacus/core';

export function getGlobalMessageType(
  messageType: MessageTypeEnum
): GlobalMessageType {
  switch (messageType) {
    case MessageTypeEnum.ERROR:
      return GlobalMessageType.MSG_TYPE_ERROR;
    case MessageTypeEnum.WARN:
      return GlobalMessageType.MSG_TYPE_WARNING;
    default:
      return GlobalMessageType.MSG_TYPE_INFO;
  }
}
