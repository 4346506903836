import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import {
  CmsBannerCarouselComponent,
  CmsService,
  ContentSlotComponentData,
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { filter, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-efa-banner-carousel',
  templateUrl: './efa-banner-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaBannerCarouselComponent {
  @HostBinding('class') styleClasses: string | undefined;
  timeout: number;

  private componentData$: Observable<CmsBannerCarouselComponent> =
    this.componentData.data$.pipe(
      filter((data: CmsBannerCarouselComponent) => Boolean(data)),
      tap((data: CmsBannerCarouselComponent) => {
        this.styleClasses = data.styleClasses;
        // We should remove casting to any once the CmsBannerCarouselComponent is exported for augmentation.
        this.timeout =
          (data as any).timeout != null ? (data as any).timeout * 1000 : 0;
      })
    );

  private items$: Observable<Observable<ContentSlotComponentData>[]> =
    this.componentData$.pipe(
      map(
        (data: CmsBannerCarouselComponent) =>
          data.banners?.trim().split(' ') ?? []
      ),
      map((codes: string[]) =>
        codes.map((code) => this.cmsService.getComponentData(code))
      )
    );

  constructor(
    private componentData: CmsComponentData<CmsBannerCarouselComponent>,
    private cmsService: CmsService
  ) {}

  getItems(): Observable<Observable<ContentSlotComponentData>[]> {
    return this.items$;
  }
}
