export const cartAdministration = {
  cartAdministration: {
    orderTypeSelect: {
      label: 'Order type',
      placeholder: 'Order type',
      forwardOrder: 'Forward order'
    },
    orderDataForm: {
      formHeadline: 'Order data',
      deliveryAddress: {
        label: 'Delivery address',
        placeholder: 'delivery address'
      },
      deliveryDate: {
        label: 'Desired delivery date',
        placeholder: 'Desired delivery date'
      },
      shippingType: {
        label: 'Shipping method',
        placeholder: 'choose shipping method',
        hint: '(Please choose the required value)',
        parcelService: 'Parcel service'
      },
      submit: 'choose'
    },
    cartProductSearchForm: {
      formHeadline: 'Direct order with article number',
      productCategories: {
        label: 'Search in category:',
        spareParts: 'OE parts and Ident parts',
        oil: 'Oil',
        accessories: 'Accessories',
        tools: 'Tools'
      },
      manufacturerCodes: {
        label: 'Select spare parts brands',
        placeholder: 'Brand'
      },
      oemNumber: {
        label: 'Item number',
        placeholder: 'Item number'
      },
      quantity: {
        label: 'Amount',
        placeholder: 'Amount'
      },
      consignmentInfo: {
        label: 'Commission',
        placeholder: 'Commission (max. 40 chars)',
        placeholderSimple: '(max. 40 chars)'
      },
      searchForAlternativeArticles: 'Alternative article',
      submit: 'Add article'
    },
    modalDialog: {
      modalDialogBtn: 'Apply selection',
      selectDeselectAll: 'Select all'
    },
    cartProductSearchResult: {
      article: 'Article',
      articleId: 'ID {{oemNumber}}',
      brand: 'brand',
      quantity: 'Available quantity',
      warehouse: 'Warehouse',
      price: 'Price'
    },
    globalMessage: {
      productSearchNoResult: 'There aren\'t any articles searching for article number \'{{articleNumber}}\' in category \'{{category}}\'. Please check your selection of brands or category.',
      multiAddToCartFailure: 'There was an error adding item to the shopping cart. Try it again.',
      cartProductSearchFailure: 'There was an error when searching for articles. Please try again or contact the support.',
      updateCartFailure: 'There was an error when updating cart. Please try again or contact the support.',
      articleAlreadyInCart: 'The requested article {{requestedArticle}} is already in cart with maximum quantity and has a successor. Please increase the quantity of the successor.',
      productSearchNotPurchasableArticle: {
        reason_articleNotPurchasable: 'The article you are searching for is not purchasable any more.',
        reason_articleNotFound: 'Article {{oemNumber}} not found.',
        reason_articleCannotBeDelivered: 'Article {{oemNumber}} cannot be delivered anymore.',
        reason_articleNotApprovedForSale: 'Article {{oemNumber}}  is currently not approved for sale.'
      }
    },
    cartItems: {
      available: 'Accessible',
      status: 'Status',
      deliveryDate: 'In advance delivery date',
      warehouse: 'Warehouse',
      action: 'Action',
      brand: 'Brand',
      commission: 'commission',
      wishlist: 'Wishlist',
      searchedArticleNumber: 'Searched Article.Nr.',
      substitutInfoMessage:'The part originally looked for is unavailable, and has therefore been replaced by the one now shown.',
      defectiveSubstitutInfoMessage:'The article is currently not available for ordering.',
      actions: {
        editCommission: 'Edit commission',
        hideCommission: 'Hide commission',
        delete: 'Delete'
      }
    },
    cart: {
      actions: 'Organize shopping cart',
      delete: 'Delete cart',
      deleteDialog: {
        header: 'Delete cart',
        infoText: 'Do you really want to delete the current cart?',
        buttonClose: 'Cancel',
        buttonConfirm: 'Ok'
      },
      calculateModeManual: 'Calculate shopping cart manually',
      calculateModeAutomatic: 'Calculate shopping cart automatically',
      calculateModeAutomaticHint: 'The input is accelerated when the automatic calculation of the shopping cart is deactivated.',
      calculateModeManualHint: 'The input is accelerated when the automatic calculation of the shopping cart is deactivated.',
      calculate: 'Calculate Cart',
    },
    orderCost: {
      atTax: 'AT tax',
      noticeATTax: 'Used parts tax applies for this item.',
      noticeConfiguratorTaxAndDeliveryCosts: 'Sales prices incl. VAT, plus shipping costs if applicable, plus assembly costs if applicable',
    },
    orderInfoFields: {
      mandatoryMessage: 'Mandatory fields are marked with a *.',
      requiredFieldMessage: 'This field is required.'
    },
    miniLogin: {
      userGreeting: 'Hello {{name}}',
      signInRegister: 'Login / Register',
    },
    configuratorCartConfirmationDialog: {
      confirmationMessageTitle: 'Cart of wheel configurator',
      confirmationMessageText: 'You are currently in the shopping cart of the wheel configurator. After completing the order, you will be directed back to your original shopping cart.',
      confirm: 'Agree',
      openCart:'To the cart',
    }
  }
};
