import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CartLimitExceededConfirmationDialogComponent } from '../components/cart-limit-exceeded-confirmation-dialog/cart-limit-exceeded-confirmation-dialog.component';

export const cartLimitExceededConfirmationLayoutConfig: LayoutConfig = {
  launch: {
    CART_LIMIT_EXCEEDED: {
      inlineRoot: true,
      component: CartLimitExceededConfirmationDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
      multi: false,
    },
  },
};
