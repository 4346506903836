import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, LanguageService } from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { ICON_TYPE } from '../../model/faq-items-icon.model';
import { FaqOverviewResponse } from '../../model/faq-items.model';
import { FaqOverviewService } from '../../services/faq-overview.service';

@Component({
  selector: 'app-faq-overview',
  templateUrl: './faq-overview.component.html',
})
export class FaqOverviewComponent implements OnInit, OnDestroy {
  faqOverviewResponse$: Observable<FaqOverviewResponse>;
  readonly iconType = ICON_TYPE;

  private readonly faqItemDetailsRoute: string = 'faqItemDetails';
  private readonly faqItemDetailsAnonymousRoute = 'faqItemDetailsAnonymous';
  private subscription: Subscription = new Subscription();
  private isUserLoggedIn: boolean;

  constructor(
    protected faqOverviewService: FaqOverviewService,
    protected authService: AuthService,
    protected languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.faqOverviewService.resetFaqOverview();
    this.subscription.add(
      combineLatest([
        this.authService.isUserLoggedIn().pipe(first()),
        this.languageService.getActive().pipe(distinctUntilChanged()),
      ]).subscribe(([isUserLoggedIn]: [boolean, string]) => {
        this.isUserLoggedIn = isUserLoggedIn;
        this.faqOverviewService.loadOverview(isUserLoggedIn);
      })
    );

    this.faqOverviewResponse$ =
      this.faqOverviewService.getFaqOverviewResponse();
  }

  getFaqItemClass(index: number): string {
    return index === 0 ? 'mb-2' : 'my-2';
  }

  getForwardRoute(): string {
    return this.isUserLoggedIn
      ? this.faqItemDetailsRoute
      : this.faqItemDetailsAnonymousRoute;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
