import { Component, Input } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';

@Component({
  selector: 'app-order-shipping-cost-notice',
  templateUrl: './order-shipping-cost-notice.component.html',
})
export class OrderShippingCostNoticeComponent {
  @Input() cart: Cart;
}
