import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CategoryEffects } from './store/category.effects';
import { categoryReducer, metaReducers } from './store/category.reducer';

import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  OccConfig,
  PageMetaResolver,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  CategoryNavigationModule,
  GenericLinkModule,
  IconModule,
  NavigationModule,
} from '@spartacus/storefront';
import { CategoryNavigationUIComponent } from './components/category-navigation-ui/category-navigation-ui.component';
import { EfaCategoryNavigationComponent } from './components/efa-category-navigation/efa-category-navigation.component';
import { CustomCategoryPageMetaResolver } from './meta-resolver/custom-category-page-meta-resolver';
import { CustomContentPageMetaResolver } from './meta-resolver/custom-content-page-meta-resolver';
import { CustomProductPageMetaResolver } from './meta-resolver/custom-product-page-meta-resolver';
import { CustomSearchPageMetaResolver } from './meta-resolver/custom-search-page-meta-resolver';
import {
  CATEGORY_NORMALIZER,
  OccCategoryAdapter,
} from './occ/category.adapter';
import { OccCategoryNormalizer } from './occ/category.normalizer';
import { CATEGORY_FEATURE } from './store/category.state';
import {
  categoryInformationTranslationChunksConfig,
  categoryInformationTranslations,
} from './translations/category-information.translations';

@NgModule({
  declarations: [
    CategoryNavigationUIComponent,
    EfaCategoryNavigationComponent,
  ],
  imports: [
    CommonModule,
    CategoryNavigationModule,
    I18nModule,
    RouterModule,
    IconModule,
    GenericLinkModule,
    NavigationModule,
    StoreModule.forFeature(CATEGORY_FEATURE, categoryReducer, { metaReducers }),
    EffectsModule.forFeature([CategoryEffects]),
    ConfigModule.withConfig({
      i18n: {
        resources: categoryInformationTranslations,
        chunks: categoryInformationTranslationChunksConfig,
      },
      backend: {
        occ: {
          endpoints: {
            category: '/categories/${categoryId}?fields=DEFAULT',
          },
        },
      },
    } as OccConfig),
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CategoryNavigationComponent: {
          component: EfaCategoryNavigationComponent,
          data: {
            resetMenuOnClose: true,
          },
        },
      },
    }),
    {
      provide: CATEGORY_NORMALIZER,
      useExisting: OccCategoryNormalizer,
      multi: true,
    },
    {
      provide: OccCategoryAdapter,
      useClass: OccCategoryAdapter,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomCategoryPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomProductPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomContentPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: CustomSearchPageMetaResolver,
      multi: true,
    },
  ],
})
export class CategoryInformationModule {}
