export enum ICON_TYPE {
  CLOSE_LIGHT = 'CLOSE_LIGHT',
  CLEAR = 'CLEAR',
}

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    MINI_CART = 'MINI_CART',
  }
}
