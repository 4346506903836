<ng-container *ngIf="cart.deliveryCostPending?.value > 0">
    <span *ngIf="cart.orderValueMissing?.value === 0; else orderValueMissingForNoShippingCostHint">
        {{ 'orderCost.noticeNoShippingCost' | cxTranslate }}
    </span>
    <ng-template #orderValueMissingForNoShippingCostHint>
        <span>
            {{ 'orderCost.noticeOrderValueMissingForNoShippingCost' | cxTranslate: {
            orderValuePriceMissing: cart.orderValueMissing?.formattedValue } }}
        </span>
    </ng-template>
</ng-container>