import { Config } from '@spartacus/core';

export abstract class FunctionsConfig {
  displayRequestedDeliveryDate?: boolean;
  manufacturersSelectionDefaultManufacturerGroupCode?: string;
  miniCartDialogNumberOfPositionsToDisplay?: number;
  showOrderLimitExceededConfirmationDialog?: boolean;
}

export const defaultFunctionsConfig: FunctionsConfig = {
  displayRequestedDeliveryDate: false,
  manufacturersSelectionDefaultManufacturerGroupCode: 'CAR',
  miniCartDialogNumberOfPositionsToDisplay: 4,
  showOrderLimitExceededConfirmationDialog: false,
};

declare module '@spartacus/core' {
  interface Config extends FunctionsConfig {}
}
