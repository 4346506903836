import { Component } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { ICON_TYPE } from '../../model/place-order-enum.model';

@Component({
  selector: 'app-cart-limit-exceeded-confirmation-dialog',
  templateUrl: './cart-limit-exceeded-confirmation-dialog.component.html',
})
export class CartLimitExceededConfirmationDialogComponent {
  readonly iconTypes = ICON_TYPE;
  constructor(private launchDialogService: LaunchDialogService) {}

  confirm(): void {
    this.closeModal('confirmed');
  }

  closeModal(status: string = 'closed'): void {
    this.launchDialogService.closeDialog(status);
  }
}
