<nav [attr.aria-label]="navAriaLabel">
    <ul>
        <ng-container *ngFor="let child of node?.children">
            <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
            </ng-container>
        </ng-container>
    </ul>
</nav>

<ng-template #nav let-node="node" let-depth="depth">
    <li>
        <cx-generic-link *ngIf="
          node.url && (!node.children || node.children?.length === 0);
          else heading
        " [url]="node.url" [target]="node.target" [style]="node.styleAttributes" [class]="node.styleClasses"
            (click)="closeIfClickedTheSameLink(node)">
            {{ node.title }}
        </cx-generic-link>

        <ng-template #heading>
            <ng-container *ngIf="flyout && node.children?.length > 0; else title">
                <span *ngIf="isOpen; else link" class="opened-node-title">
                    <cx-icon [type]="iconType.CARET_LEFT" class="small opened-node-back-icon"
                        (click)="back()"></cx-icon>
                    {{ node?.title }}
                </span>
                <ng-template #link>
                    <cx-generic-link *ngIf="node.url" [url]="node.url" [target]="node.target"
                        (click)="closeIfClickedTheSameLink(node)">
                        {{ node?.title }}
                    </cx-generic-link>
                    <button [attr.tabindex]="depth < 1 ? 0 : -1" [attr.aria-haspopup]="true"
                        [attr.aria-expanded]="false" [attr.aria-label]="node.title" (click)="toggleOpen($any($event))"
                        (mouseenter)="onMouseEnter($event)" (keydown.space)="toggleOpen($any($event))"
                        (keydown.esc)="back()" class="d-flex d-lg-none">
                        <ng-container *ngIf="!node.url">
                            {{ node.title }}
                        </ng-container>
                        <cx-icon [type]="iconType.CARET_RIGHT" class="small"></cx-icon>
                    </button>
                    <button [attr.tabindex]="depth < 1 ? 0 : -1" [attr.aria-haspopup]="true"
                        [attr.aria-expanded]="false" [attr.aria-label]="node.title" class="d-none d-lg-flex">
                        <ng-container *ngIf="!node.url">
                            {{ node.title }}
                        </ng-container>
                        <cx-icon [type]="iconType.CARET_DOWN"></cx-icon>
                    </button>
                </ng-template>
            </ng-container>
            <ng-template #title>
                <span *ngIf="node.title" [attr.tabindex]="-1">
                    {{ node.title }}
                </span>
            </ng-template>
        </ng-template>

        <div class="wrapper" *ngIf="node.children && node.children.length > 0">
            <ul class="childs" [attr.depth]="getTotalDepth(node)"
                [attr.wrap-after]="node.children.length > wrapAfter ? wrapAfter : null"
                [attr.columns]="getColumnCount(node.children.length)">
                <ng-container *ngFor="let child of node.children">
                    <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }">
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </li>
</ng-template>