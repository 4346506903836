import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ConfigModule, GlobalMessageService, I18nModule, OccConfig, UrlModule } from '@spartacus/core';
import {
  CardModule,
  FormErrorsModule,
  IconModule,
  IconResourceType,
  ItemCounterModule,
  KeyboardFocusTestingModule,
  LayoutConfig,
  MediaModule,
  OutletModule,
  PageLayoutModule,
  PageSlotModule,
  PopoverModule,
  SpinnerModule,
} from '@spartacus/storefront';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CartSubEntryComponent } from '@shared/components/cart-sub-entry/cart-sub-entry.component';
import { DeliveryNotesHeaderComponent } from '@shared/components/delivery-notes-header/delivery-notes-header.component';
import { EfaAddToWishListComponentBaseComponent } from '@shared/components/efa-add-to-wish-list-base/efa-add-to-wish-list-base.component';
import { EfaAddToWishListComponent } from '@shared/components/efa-add-to-wish-list/efa-add-to-wish-list.component';
import { EfaCartCheckoutSpinnerComponent } from '@shared/components/efa-cart-checkout-spinner/efa-cart-checkout-spinner.component';
import {
  EfaCartItemAddToWishListComponent
} from '@shared/components/efa-cart-item-add-to-wish-list/efa-cart-item-add-to-wish-list.component';
import { EfaCartItemListComponent } from '@shared/components/efa-cart-item-list/efa-cart-item-list.component';
import { EfaCartItemComponent } from '@shared/components/efa-cart-item/efa-cart-item.component';
import { EfaGlobalMessageComponent } from '@shared/components/efa-global-message/efa-global-message.component';
import { EfaItemCounterComponent } from '@shared/components/efa-item-counter/efa-item-counter.component';
import { EfaOrderSummaryComponent } from '@shared/components/efa-order-summary/efa-order-summary.component';
import { EfaStorefrontComponent } from '@shared/components/efa-storefront/efa-storefront.component';
import { NoDisplayResultNoticeComponent } from '@shared/components/no-display-result-notice/no-display-result-notice.component';
import { OrderInfoFieldsComponent } from '@shared/components/order-info-fields/order-info-fields.component';
import { OccModule } from '@shared/occ/occ.module';
import { OrderByPipe } from '@shared/pipes/order-By.pipe';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { effects } from '@shared/store/effects';
import { reducers, SHARED_INDEX_FEATURE_KEY } from '@shared/store/reducers';
import { AddToWishListModule } from '@spartacus/cart/wish-list/components/add-to-wishlist';
import { ProductBadgeComponent } from './components/badge/product-badge.component';
import { EfaPasswordVisibilityToggleComponent } from './components/efa-password-visibility-toggle/efa-password-visibility-toggle.component';
import { ErrorPageRedirectorComponent } from './components/error-page-redirector/error-page-redirector.component';
import { ProcessNavigationComponent } from './components/process-navigation/process-navigation.component';
import { ReturnHistoryHeaderComponent } from './components/return-history-header/return-history-header.component';
import { OrderShippingCostNoticeComponent } from './components/order-shipping-cost-notice/order-shipping-cost-notice.component';
import { EfaAutoFocus } from './directives/efa-auto-focus.directive';
import { EfaAutoScroll } from './directives/efa-auto-scroll.directive';
import { EfaNgbDatepickerNavigationRemoveTooltipsDirective } from './directives/efa-ngb-date-picker-navigation-remove-tooltips.directive';
import { PageNotFoundRedirectionGuard } from './guards/page-not-found-redirection.guard';
import { FormatUsernamePipe } from './pipes/format-username.pipe';
import { JoinByPipe } from './pipes/join-By.pipe';
import { LocalizeToPipe } from './pipes/localize-to.pipe';
import { ReverseUsernamePipe } from './pipes/revers-username.pipe';
import { EfaNgbDatepickerI18n } from './services/efa-ngb-datepicker-i18n.service';

@NgModule({
  declarations: [
    DeliveryNotesHeaderComponent,
    EfaOrderSummaryComponent,
    EfaCartItemComponent,
    EfaCartItemListComponent,
    CartSubEntryComponent,
    OrderInfoFieldsComponent,
    OrderByPipe,
    JoinByPipe,
    LocalizeToPipe,
    FormatUsernamePipe,
    ReverseUsernamePipe,
    EfaItemCounterComponent,
    EfaAddToWishListComponent,
    NoDisplayResultNoticeComponent,
    EfaAddToWishListComponentBaseComponent,
    EfaCartItemAddToWishListComponent,
    EfaPasswordVisibilityToggleComponent,
    EfaNgbDatepickerNavigationRemoveTooltipsDirective,
    EfaAutoFocus,
    EfaAutoScroll,
    ProcessNavigationComponent,
    ReturnHistoryHeaderComponent,
    EfaGlobalMessageComponent,
    EfaStorefrontComponent,
    ProductBadgeComponent,
    OrderShippingCostNoticeComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    I18nModule,
    UrlModule,
    ReactiveFormsModule,
    CardModule,
    MediaModule,
    OutletModule,
    ItemCounterModule,
    AddToWishListModule,
    IconModule,
    RouterModule,
    SpinnerModule,
    FormErrorsModule,
    OccModule,
    NgbModule,
    PopoverModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            allowedFileExtensions: 'allowedFileExtensions/${contextId}',
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      layoutSlots: {
        ContentPage1Template: {
          slots: ['Section1', 'Section2A', 'Section2B', 'Section3','TopFooter'],
        },
        LandingPage2Template: {
          slots: ['Section1',
            'Section2A',
            'Section2B',
            'Section2C',
            'Section3',
            'Section4',
            'Section5',
            'TopFooter'],
        },
        CartPageTemplate: {
          slots: ['TopContent', 'CenterRightContentSlot', 'EmptyCartMiddleContent', 'TopFooter'],
        },
        CategoryPageTemplate: {
          pageFold: 'Section2',
          slots: ['Section1', 'Section2', 'Section3', 'TopFooter'],
        },
        ProductListPageTemplate: {
          slots: ['ProductLeftRefinements', 'ProductListSlot', 'TopFooter'],
        },
        ProductGridPageTemplate: {
          slots: ['ProductLeftRefinements', 'ProductGridSlot', 'TopFooter'],
        },
        SearchResultsListPageTemplate: {
          slots: [
            'Section2',
            'ProductLeftRefinements',
            'SearchResultsListSlot',
            'Section4', 'TopFooter'
          ],
        },
        SearchResultsGridPageTemplate: {
          slots: [
            'Section2',
            'ProductLeftRefinements',
            'SearchResultsGridSlot',
            'Section4',
            'TopFooter'
          ],
        },
        ProductDetailsPageTemplate: {
          slots: [
            'Summary',
            'UpSelling',
            'CrossSelling',
            'Tabs',
            'PlaceholderContentSlot',
            'TopFooter'
          ],
        },
        AccountPageTemplate: {
          slots: ['BodyContent', 'BottomContent', 'SideContent', 'TopFooter'],
        },
        ErrorPageTemplate: {
          slots: ['TopContent', 'MiddleContent', 'BottomContent', 'TopFooter'],
        },
        OrderConfirmationPageTemplate: {
          slots: ['BodyContent', 'SideContent', 'TopFooter'],
        },
        MultiStepCheckoutSummaryPageTemplate: {
          slots: ['TopContent', 'BodyContent', 'SideContent', 'BottomContent', 'TopFooter'],
        },
      },
    } as LayoutConfig),
    StoreModule.forFeature(SHARED_INDEX_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
    FormErrorsModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          EMPTY_HEART: 'EMPTY_HEART',
          HEART: 'HEART',
          EMPTY_BLUE_HEART:'EMPTY_BLUE_HEART',
          BLUE_HEART:'BLUE_HEART',
          PENCIL: 'PENCIL',
          CARET_UP: 'CARET_UP',
          ARROW_UP: 'ARROW_UP',
          ARROW_LINE_UP: 'ARROW_LINE_UP',
          ARROW_EXPAND_LEFT: 'ARROW_EXPAND_LEFT',
          CARET_DOWN: 'CARET_DOWN',
          COMMUNICATION_CONTACT_PHONE_BLUE: 'COMMUNICATION_CONTACT_PHONE',
          RESET: 'fas fa-times-circle',
          SPINNER: 'fas fa-spinner fa-spin',
          GLOBE: 'globe',
          HELP: 'help',
          PHONE: 'phone',
          CALENDAR: 'calendar',
          BROWSE: 'browse',
          HINT: 'hint',
          DELETEFOREVER: 'deleteForever',
          DOCUMENTPDF: 'DocumentPDF',
          DOWNLOAD_BLUE: 'DOWNLOAD',
          UPLOAD_BLUE: 'UPLOAD',
          CLEAR: 'CLEAR',
          TRASH_BLUE: 'TRASH',
          CART_BLUE: 'CART',
          CART_WHITE: 'CART_WHITE',
          ADD_CIRCLE: 'add-circle',
          PDF: 'pdf',
          IMAGE: 'IMAGE',
          DELETE: 'delete',
          BLACK_ICON_DELETE:'BLACK_ICON_DELETE',
          CARET_RIGHT: 'caret-right',
          CARET_LEFT: 'caret-left',
          EYE: 'EYE',
          EYE_CROSSED: 'EYE_CROSSED',
          LIST: 'LIST',
          SEARCH2: 'SEARCH2',
          CHECK_CIRCLE_FILLED_SUCCESS: 'CHECK_CIRCLE_FILLED_SUCCESS',
          CHECK_CIRCLE_FILLED: 'CHECK_CIRCLE_FILLED',
          NUMBER_CIRCLE_OUTLINE_2: 'NUMBER_CIRCLE_OUTLINE_2',
          NUMBER_CIRCLE_OUTLINE_3: 'NUMBER_CIRCLE_OUTLINE_3',
          NUMBER_CIRCLE_OUTLINE_4: 'NUMBER_CIRCLE_OUTLINE_4',
          NUMBER_CIRCLE_OUTLINE_5: 'NUMBER_CIRCLE_OUTLINE_5',
          NUMBER_CIRCLE_FILLED_1: 'NUMBER_CIRCLE_FILLED_1',
          NUMBER_CIRCLE_FILLED_2: 'NUMBER_CIRCLE_FILLED_2',
          NUMBER_CIRCLE_FILLED_3: 'NUMBER_CIRCLE_FILLED_3',
          NUMBER_CIRCLE_FILLED_4: 'NUMBER_CIRCLE_FILLED_4',
          NUMBER_CIRCLE_FILLED_5: 'NUMBER_CIRCLE_FILLED_5',
          INFOCIRCLEFILLED: 'infoCircleFilled',
          PLUS: 'PLUS',
          CLOSE_LIGHT: 'CLOSE_LIGHT',
          ARROW_EXPAND_DOWN: 'ARROW_EXPAND_DOWN',
          LOGOUT: 'LOGOUT',
        },
        resources: [
          {
            type: IconResourceType.LINK,
            url: 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/globe.svg',
            types: ['GLOBE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/help.svg',
            types: ['HELP'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/phone.svg',
            types: ['PHONE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/calendar.svg',
            types: ['CALENDAR'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/browse.svg',
            types: ['BROWSE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/hint.svg',
            types: ['HINT'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/deleteForever.svg',
            types: ['DELETEFOREVER'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/documentPDF.svg',
            types: ['DOCUMENTPDF'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/cartBlue.svg',
            types: ['CART_BLUE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/cartWhite.svg',
            types: ['CART_WHITE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/trash.svg',
            types: ['TRASH'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/trashBlue.svg',
            types: ['TRASH_BLUE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/clear.svg',
            types: ['CLEAR'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/upload.svg',
            types: ['UPLOAD'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/download.svg',
            types: ['DOWNLOAD'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/uploadBlue.svg',
            types: ['UPLOAD_BLUE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/downloadBlue.svg',
            types: ['DOWNLOAD_BLUE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/heart.svg',
            types: ['HEART'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/emptyBlueHeart.svg',
            types: ['EMPTY_BLUE_HEART'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/blueHeart.svg',
            types: ['BLUE_HEART'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/emptyHeart.svg',
            types: ['EMPTY_HEART'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/arrowUp.svg',
            types: ['ARROW_UP'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/arrow -line-up.svg',
            types: ['ARROW_LINE_UP'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/arrowExpandLeft.svg',
            types: ['ARROW_EXPAND_LEFT'],
          },

          {
            type: IconResourceType.SVG,
            url: './assets/icons/arrow -line-up.svg',
            types: ['ARROW_LINE_UP'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/pencil.svg',
            types: ['PENCIL'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/caretUp.svg',
            types: ['CARET_UP'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/caretDown.svg',
            types: ['CARET_DOWN'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/communicationContactPhoneBlue.svg',
            types: ['COMMUNICATION_CONTACT_PHONE_BLUE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/add-circle.svg',
            types: ['ADD_CIRCLE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/pdf.svg',
            types: ['PDF'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/image.svg',
            types: ['IMAGE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/delete.svg',
            types: ['DELETE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/blackIconDelete.svg',
            types: ['BLACK_ICON_DELETE'],
          },

          {
            type: IconResourceType.SVG,
            url: './assets/icons/caret-right.svg',
            types: ['CARET_RIGHT'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/caret-left.svg',
            types: ['CARET_LEFT'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/eye.svg',
            types: ['EYE'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/eyeCrossed.svg',
            types: ['EYE_CROSSED'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/list.svg',
            types: ['LIST'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/search.svg',
            types: ['SEARCH2'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/checkCircleFilled-success.svg',
            types: ['CHECK_CIRCLE_FILLED_SUCCESS'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/checkCircleFilled.svg',
            types: ['CHECK_CIRCLE_FILLED'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleOutline-2.svg',
            types: ['NUMBER_CIRCLE_OUTLINE_2'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleOutline-3.svg',
            types: ['NUMBER_CIRCLE_OUTLINE_3'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleOutline-4.svg',
            types: ['NUMBER_CIRCLE_OUTLINE_4'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleOutline-5.svg',
            types: ['NUMBER_CIRCLE_OUTLINE_5'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleFilled-1.svg',
            types: ['NUMBER_CIRCLE_FILLED_1'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleFilled-2.svg',
            types: ['NUMBER_CIRCLE_FILLED_2'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleFilled-3.svg',
            types: ['NUMBER_CIRCLE_FILLED_3'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleFilled-4.svg',
            types: ['NUMBER_CIRCLE_FILLED_4'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/numberCircleFilled-5.svg',
            types: ['NUMBER_CIRCLE_FILLED_5'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/closeLight.svg',
            types: ['CLOSE_LIGHT'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/plus.svg',
            types: ['PLUS'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/arrow-expand-down.svg',
            types: ['ARROW_EXPAND_DOWN'],
          },

          {
            type: IconResourceType.SVG,
            url: './assets/icons/infoCircleFilled.svg',
            types: ['INFOCIRCLEFILLED'],
          },
          {
            type: IconResourceType.SVG,
            url: './assets/icons/logout.svg',
            types: ['LOGOUT'],
          },
        ],
      },
      cmsComponents: {
        AddToWishListComponent: {
          component: EfaAddToWishListComponent,
        },
        CartCheckoutSpinnerComponent: {
          component: EfaCartCheckoutSpinnerComponent,
        },
        ProcessNavigationComponent: {
          component: ProcessNavigationComponent,
        },
        ErrorPageRedirectorComponent: {
          component: ErrorPageRedirectorComponent,
          guards: [PageNotFoundRedirectionGuard],
        }
      },
    }),
    ConfigModule.withConfig({
      useLegacyMediaComponent: true,
    }),
    IconModule,
    CommonModule,
    NgbModule,
    PageLayoutModule,
    PageSlotModule,
    KeyboardFocusTestingModule,
  ],
  providers: [
    {
      provide: NgbDatepickerI18n,
      useClass: EfaNgbDatepickerI18n,
    },
    {
      provide: GlobalMessageService,
      useClass: EfaGlobalMessageService,
    },
  ],
  exports: [
    DeliveryNotesHeaderComponent,
    EfaOrderSummaryComponent,
    EfaCartItemComponent,
    EfaCartItemListComponent,
    CartSubEntryComponent,
    OrderInfoFieldsComponent,
    OrderByPipe,
    JoinByPipe,
    LocalizeToPipe,
    FormatUsernamePipe,
    ReverseUsernamePipe,
    EfaItemCounterComponent,
    EfaAddToWishListComponent,
    NoDisplayResultNoticeComponent,
    EfaCartItemAddToWishListComponent,
    EfaPasswordVisibilityToggleComponent,
    EfaNgbDatepickerNavigationRemoveTooltipsDirective,
    EfaAutoFocus,
    EfaAutoScroll,
    ReturnHistoryHeaderComponent,
    EfaGlobalMessageComponent,
    EfaStorefrontComponent,
    ProductBadgeComponent,
    OrderShippingCostNoticeComponent,
  ],
})
export class SharedModules {}
