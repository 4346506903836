import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { AddToCartDialogComponent } from '../functions/add-to-cart/components/add-to-cart-dialog/add-to-cart-dialog.component';

export const defaultAddToCartLayoutConfig: LayoutConfig = {
  launch: {
    ADD_TO_CART: {
      inlineRoot: true,
      component: AddToCartDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
      multi: false,
    },
  },
};

export const ADD_TO_CART = 'ADD_TO_CART';
