import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { MiniCartDialogComponent } from '../components/mini-cart-dialog/mini-cart-dialog.component';

export const defaultMiniCartLayoutConfig: LayoutConfig = {
  launch: {
    MINI_CART: {
      inline: true,
      component: MiniCartDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER,
    },
  },
};
