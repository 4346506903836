import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {EffectsModule} from '@ngrx/effects';
import {META_REDUCERS, StoreModule} from '@ngrx/store';
import {ConfigModule, I18nModule, provideConfig, UrlModule} from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  KeyboardFocusModule,
  OutletModule,
  PageSlotModule,
  PromotionsModule,
  SpinnerModule
} from '@spartacus/storefront';

import {cartAdministrationTranslationChunksConfig, cartAdministrationTranslations} from './translations/cart-administration.translations';
import {cartAdministrationFeatureKey, reducers} from './store/reducers';
import {effects} from './store/effects';
import {OccModule} from './occ/occ.module';
import {OrderTypeSelectionComponent} from './components/order-type-selection/order-type-selection.component';
import {CartHeaderComponent} from './components/cart-header/cart-header.component';
import {CartProductSearchComponent} from './components/cart-product-search/cart-product-search.component';
import {ManufacturersSelectionComponent} from './components/manufacturers-selection/manufacturers-selection.component';
import {CartProductSearchResultComponent} from './components/cart-product-search-result/cart-product-search-result.component';
import {OrderByPipe} from './shared/pipes/order-By.pipe';
import {EfaCartDetailsComponent} from './components/efa-cart-details/efa-cart-details.component';
import {EfaCartTotalsComponent} from './components/efa-cart-totals/efa-cart-totals.component';
import {NgbDateCustomParserFormatter} from './shared/utils/ngb-custom-date-parser-formatter';
import {metaReducerFactory} from './store/reducers/set-cart-entry-infos.reducer';
import {EfaLoginComponent} from './components/efa-login/efa-login.component';
import {ImportExportModule} from '../import-export/import-export.module';
import {CartDeleteComponent} from './components/cart-delete/cart-delete.component';
import {SharedModules} from '@shared/shared.modules';
import {EfaCartItemComponent} from '@shared/components/efa-cart-item/efa-cart-item.component';
import {CartActionsComponent} from './components/cart-actions/cart-actions.component';
import {CartHeaderSummaryComponent} from './components/cart-header-summary/cart-header-summary.component';
import {CartDeleteDialogComponent} from './components/cart-delete-dialog/cart-delete-dialog.component';
import {CartCalculateModeComponent} from './components/cart-calculate-mode/cart-calculate-mode.component';
import {ConfiguratorCartConfirmationComponent} from './components/configurator-cart-confirmation/configurator-cart-confirmation.component';
import {
  ConfiguratorCartConfirmationMessageComponent
} from './components/configurator-cart-confirmation-message/configurator-cart-confirmation-message.component';
import {defaultCartDeleteLayoutConfig} from './cart-delete-layout-config';
import {defaultCartProductSearchResultLayoutConfig} from './cart-product-search-result-layout-config';
import {defaultManufacturersSelectionLayoutConfig} from './manufacturers-selection-layout-config';
import {CartItemContextSource, CartSharedModule} from '@spartacus/cart/base/components';
import {CartItemContext} from '@spartacus/cart/base/root';
import { CartBaseModule } from '@spartacus/cart/base';

@NgModule({
  declarations: [
    OrderTypeSelectionComponent,
    CartHeaderComponent,
    CartProductSearchComponent,
    ManufacturersSelectionComponent,
    CartProductSearchResultComponent,
    OrderByPipe,
    EfaCartDetailsComponent,
    EfaCartTotalsComponent,
    EfaLoginComponent,
    CartDeleteComponent,
    CartActionsComponent,
    CartHeaderSummaryComponent,
    CartDeleteDialogComponent,
    CartCalculateModeComponent,
    ConfiguratorCartConfirmationComponent,
    ConfiguratorCartConfirmationMessageComponent
  ],
  imports: [
    CommonModule,
    OccModule,
    I18nModule,
    IconModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    NgbModule,
    SpinnerModule,
    PromotionsModule,
    RouterModule,
    OutletModule,
    UrlModule,
    CartBaseModule,
    CartSharedModule,
    PageSlotModule,
    SharedModules,
    KeyboardFocusModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          SEARCH: 'fas fa-search',
//          TRASH: 'fas fa-trash',
          ACTIVE: 'fas fa-check',
        }
      }
    }),
    ConfigModule.withConfig({
      i18n: {
        resources: cartAdministrationTranslations,
        chunks: cartAdministrationTranslationChunksConfig
      },
      cmsComponents: {
        OrderTypeSelectionComponent: {
          component: OrderTypeSelectionComponent,
        },
        CartHeaderComponent: {
          component: CartHeaderComponent,
        },
        CartProductSearchComponent: {
          component: CartProductSearchComponent,
        },
        CartItemComponent: {
          component: EfaCartItemComponent
        },
        CartComponent: {
          component: EfaCartDetailsComponent
        },
        CartTotalsComponent: {
          component: EfaCartTotalsComponent,
        },
        CartActionsComponent: {
          component: CartActionsComponent,
        },
        LoginComponent: {
          component: EfaLoginComponent,
        },
        ConfiguratorCartConfirmationComponent: {
          component: ConfiguratorCartConfirmationComponent,
        },
      },
      backend: {
        occ: {
          endpoints: {
            /* tslint-disable max-len */
            carts:
              'users/${userId}/carts?fields=carts(DEFAULT,totalExchangeTax(formattedValue),orderInfoFields(DEFAULT),entries(DEFAULT,totalPrice(formattedValue),product(images(FULL),stock(FULL),manufacturerAID),basePrice(formattedValue,value),updateable,deliveryItems(DEFAULT),positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue),consignmentInfo,parent,substitutedOemNumber,info,orderInfoFields(DEFAULT),sapPositionNumber,subEntries(DEFAULT,positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue),sapPositionNumber)),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryCost(formattedValue),totalTax(formattedValue, value),user,saveTime,name,description,deliveryItemsQuantity,orderType(DEFAULT),requestedDeliveryDate,deliveryAddress(FULL),deliveryMode(DEFAULT),code,calculatedForCheckout,skipAutomaticCalculation)',
            cart:
              'users/${userId}/carts/${cartId}?fields=DEFAULT,totalExchangeTax(formattedValue),orderInfoFields(DEFAULT),entries(DEFAULT,totalPrice(formattedValue),product(images(FULL),stock(FULL),manufacturerAID),basePrice(formattedValue,value),updateable,deliveryItems(DEFAULT),positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue),consignmentInfo,parent,substitutedOemNumber,info,orderInfoFields(DEFAULT),sapPositionNumber,subEntries(DEFAULT,positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue),sapPositionNumber)),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryCost(formattedValue),totalTax(formattedValue, value),user,saveTime,name,description,deliveryItemsQuantity,orderType(DEFAULT),requestedDeliveryDate,deliveryAddress(FULL),deliveryMode(DEFAULT),code,calculatedForCheckout,skipAutomaticCalculation,deliveryCostPending(formattedValue,value),orderValueMissing(formattedValue,value)',
            createCart:
              'users/${userId}/carts?fields=DEFAULT,totalExchangeTax(formattedValue),orderInfoFields(DEFAULT),entries(DEFAULT,totalPrice(formattedValue),product(images(FULL),stock(FULL),manufacturerAID),basePrice(formattedValue,value),updateable,deliveryItems(DEFAULT),positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue),consignmentInfo,parent,substitutedOemNumber,info,orderInfoFields(DEFAULT),sapPositionNumber,subEntries(DEFAULT,positionTypeName,exchangeTaxRequired,customerTotalPrice(formattedValue),customerBasePrice(formattedValue),sapPositionNumber)),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryCost(formattedValue),totalTax(formattedValue, value),user,deliveryItemsQuantity,orderType(DEFAULT),requestedDeliveryDate,deliveryAddress(FULL),deliveryMode(DEFAULT),calculatedForCheckout,skipAutomaticCalculation',
            cartProductSearch:
              'users/${userId}/cartProductSearch?fields=DEFAULT',
            setCartEntryInfos:
              'users/${userId}/carts/${cartId}/entries/${entryNumber}/setCartEntryInfos',
            updateCart:
              'users/${userId}/carts/${cartId}/updateCart',
            multiAddEntries:
              'users/${userId}/carts/${cartId}/addEntries',
            setCartCalculationMode:
              'users/${userId}/setCartCalculationMode?automatic=${automatic}&cartId=${cartId}',
            /* tslint-enable */
          }
        }
      }
    }),
    StoreModule.forFeature(cartAdministrationFeatureKey, reducers),
    EffectsModule.forFeature(effects),
    ImportExportModule,
    NgSelectModule
  ],
    exports: [
        OrderTypeSelectionComponent,
        CartHeaderComponent,
        CartProductSearchComponent,
        EfaCartDetailsComponent,
        CartDeleteComponent,
        ManufacturersSelectionComponent
    ],
  providers: [
    CartItemContextSource,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    },
    {
      provide: CartItemContext,
      useExisting: CartItemContextSource
    },
    {
      provide: META_REDUCERS,
      deps: [],
      useFactory: metaReducerFactory,
      multi: true,
    },
    provideConfig(defaultManufacturersSelectionLayoutConfig),
    provideConfig(defaultCartDeleteLayoutConfig),
    provideConfig(defaultCartProductSearchResultLayoutConfig),
  ]
})
export class CartAdministrationModule {
}
