<ng-container *ngIf="items.length > 0; else emptyCart">
  <ng-container *ngFor="let item of items; let i = index; trackBy: trackItemByFn">
    <ng-container *ngIf="getControl(item) | async as control" class="cx-item-list-items"
                  [class.is-changed]="
              featureConfigService?.isLevel('3.1')
                ? control.get('quantity').disabled
                : control.get('quantity').dirty">
      <ng-container
        *ngIf="!item.subEntry && (cartEntriesCount <= maxItemsToShow || i < maxItemsToShow || showAllItems)">
        <app-mini-cart-item [item]="item"
                            [quantityControl]="control.get('quantity')"></app-mini-cart-item>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #emptyCart>
  <p class="text-empty-cart">{{ 'miniCart.miniCartDialog.emptyCart' | cxTranslate }}</p>
</ng-template>

<div class="mini-cart-item-list-actions">
  <button class="btn btn-link btn-show-more" type="button" (click)="toggleShowMore()"
          *ngIf="cartEntriesCount > maxItemsToShow">
    <ng-container *ngIf="!showAllItems; else showLess">
      {{ 'miniCart.miniCartDialog.actions.showMore' | cxTranslate }}
    </ng-container>
    <ng-template #showLess>
      {{ 'miniCart.miniCartDialog.actions.showLess' | cxTranslate }}
    </ng-template>
  </button>
</div>
<ng-template #loading>
  <cx-spinner></cx-spinner>
</ng-template>
