import { Component, Input } from '@angular/core';
import { EfaCartItemListComponent } from '@shared/components/efa-cart-item-list/efa-cart-item-list.component';

@Component({
  selector: 'app-mini-cart-item-list',
  templateUrl: './mini-cart-item-list.component.html',
})
export class MiniCartItemListComponent extends EfaCartItemListComponent {
  @Input() maxItemsToShow: number;
  showAllItems = false;

  toggleShowMore(): void {
    this.showAllItems = !this.showAllItems;
  }
}
