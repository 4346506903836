import { TranslationChunksConfig } from '@spartacus/core';

import { de } from './de/index-de';
import { en } from './en/index-en';

export const productPricesTranslations = {
  de,
  en,
};

export const productPricesTranslationChunksConfig: TranslationChunksConfig = {
  productPrices: ['productPrices'],
};
