export const checkoutOrder = {
  checkoutOrder: {
    globalMessage: {
      mandatoryOrderInfoFieldsMissing: 'Nicht alle Bestell- oder Kommissionsfelder wurden korrekt ausgefüllt.',
      mandatoryHeaderFieldsMissing: 'Auftragsart, Versandart und Lieferadresse müssen definiert sein.',
      placeOrderFailed: 'Bei der Erstellung oder Rückmeldung des Auftrags ist ein Fehler aufgetreten. Bitte überprüfen Sie in der Auftragshistorie im Mein Konto-Bereich, ob der Auftrag dennoch angelegt wurde.'
    },
    checkoutReview: {
      headline: 'Bestellzusammenfassung',
      orderType: 'Auftragsart',
      requestedDeliveryDate: 'Wunschliefertermin',
      placeOrder: 'Kostenpflichtig bestellen',
    },
    checkoutSubmitForm: {
      YourAdditionalInformation: {
        label: 'Ihre Auftragsreferenz / Versandlabel Information',
        placeholder: '(max. 20 Zeichen)'
      },
      orderLimitExceededConfirmationDialog: {
        confirmationMessageText: 'Da der Bestellwert einer Position mehr als 9999 Euro beträgt, wird die Bestellung manuell geprüft und freigegeben. Wir informieren Sie, sobald der nächste Schritt im Bestellprozess erfolgt.',
        confirmationMessageTitle: 'Hinweis zur Bestellung',
        buttonConfirm: 'Ok',
      },
      cartLimitExceededConfirmationDialog: {
        confirmationMessageText: 'In Ihrem Warenkorb befinden sich Positionen mit einer Menge größer als 999 oder einem Warenwert von mehr als 9999€. Bitte bestätigen Sie, dass Sie diese Artikelposition tatsächlich bestellen möchten.',
        confirmationMessageTitle: 'Hinweis zum Warenkorb',
        buttonCancel: 'Zurück zum Warenkorb',
        buttonConfirm: 'Bestellung prüfen',
      }
    },
    orderInfoFields: {
      heading: 'Zusätzliche Angaben',
      mandatoryMessage1: 'Bitte füllen Sie',
      mandatoryMessage2:  'alle nachfolgenden Pflichtfelder*',
      mandatoryMessage3: 'aus, um die Bestellung auszuführen. Falls Sie keine Angabe machen können, tragen Sie bitte “keine Angabe” in das jeweilige Feld ein.'
    },
    checkoutShipping: {
      shippingMethod: 'Lieferart',
      standardDelivery: 'Standard Delivery',
      premiumDelivery: 'Premium Delivery',
    },
    orderCost: {
      noticeConfiguratorTaxAndDeliveryCosts: 'Einkaufspreise ohne MwSt., ohne Versandkosten, ohne Montagekosten.',
    },
  }
};
