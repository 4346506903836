<div class="modal-container">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <cx-icon [type]="iconTypes.CLOSE_LIGHT"></cx-icon>
        </button>
    </div>
    <div class="modal-body">
        <div class="outer-container">
            <span class="limit-exceeded-confirmation-message-icon">
                <cx-icon [type]="iconTypes.CART_BLUE"></cx-icon>
            </span>
            <p class="limit-exceeded-confirmation-message-title">
                {{ 'checkoutOrder.checkoutSubmitForm.orderLimitExceededConfirmationDialog.confirmationMessageTitle' |
                cxTranslate }}
            </p>
            <p class="limit-exceeded-confirmation-message-text">
                {{
                'checkoutOrder.checkoutSubmitForm.orderLimitExceededConfirmationDialog.confirmationMessageText'
                | cxTranslate }}
            </p>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-primary btn-confirm" (click)="confirm()">
            {{ 'checkoutOrder.checkoutSubmitForm.orderLimitExceededConfirmationDialog.buttonConfirm' | cxTranslate }}
        </button>
    </div>
</div>