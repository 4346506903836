import { Component } from '@angular/core';
import { ICON_TYPE } from '../../model/navigation-icon.model';
import { EfaNavigationNode } from '../../model/navigation.model';
import { EfaNavigationUIComponent } from '../efa-navigation-ui/efa-navigation-ui.component';

@Component({
  selector: 'app-my-account-navigation-ui',
  templateUrl: './my-account-navigation-ui.component.html',
})
export class MyAccountNavigationUiComponent extends EfaNavigationUIComponent {
  readonly customIconType = ICON_TYPE;

  private openedNodes: EfaNavigationNode[] = [];

  isNodeOpen(node: EfaNavigationNode): boolean {
    if (this.openedNodes.length === 0) {
      this.openedNodes.push((this.node as EfaNavigationNode));
    }
    
    return this.openedNodes.findIndex((n: EfaNavigationNode) => n?.uid === node?.uid) > -1 || !this.flyout;
  }

  isNodeVisible(node: EfaNavigationNode, parent: EfaNavigationNode): boolean {
    if (node?.uid === (this.node as EfaNavigationNode)?.uid) {
      return true;
    }

     return this.openedNodes.findIndex((n: EfaNavigationNode) => n?.uid === parent?.uid) === (this.openedNodes.length - 1) || this.openedNodes.findIndex((n: EfaNavigationNode) => n?.uid === node.uid) > -1 || !this.flyout;
  }

  getBackClass(node: EfaNavigationNode): string {
    if (node?.uid === (this.node as EfaNavigationNode)?.uid && this.isOpen) {
      return 'd-lg-none'
    }

    return node?.uid !== (this.node as EfaNavigationNode)?.uid && this.openedNodes.findIndex((n: EfaNavigationNode) => n?.uid === node.uid) > -1 ? '' : 'd-none';
  }

  getBackTitleClass(node: EfaNavigationNode): string {
    if (node?.uid === (this.node as EfaNavigationNode)?.uid && this.isOpen) {
      return 'root-title-expanded'
    }
    
    return node?.uid !== (this.node as EfaNavigationNode)?.uid && this.openedNodes.findIndex((n: EfaNavigationNode) => n?.uid === node.uid) > -1 ? 'parent-title-expanded' : '';
  }

  getExpandClass(node: EfaNavigationNode): string {
    return node?.uid === (this.node as EfaNavigationNode)?.uid || this.openedNodes.findIndex((n: EfaNavigationNode) => n?.uid === node.uid) === -1 ? '' : 'd-none';
  }

  isRoot(node: EfaNavigationNode): boolean {
    return node?.uid === (this.node as EfaNavigationNode)?.uid;
  }
  
  getNodeLinkClass(node: EfaNavigationNode): string {
    return node?.uid === 'SignOutNavNode' ? 'logout': '';
  }

  getNodeLinkIconClass(node: EfaNavigationNode): string {
    return node?.uid === 'SignOutNavNode' ? 'mr-2': 'd-none';
  }

  toggleOpenNode(event: UIEvent, node: EfaNavigationNode): void {
    super.toggleOpen(event);
    this.toggleNode(node);
  }

  private toggleNode(node: EfaNavigationNode): void {
    if (node?.uid === (this.node as EfaNavigationNode)?.uid) {
      return;
    }

    const index: number = this.openedNodes.findIndex((n: EfaNavigationNode) => n?.uid === node?.uid);
    if (index > -1) {
      this.openedNodes.splice(index, 1);
    } else {
      if (this.openedNodes.length > 1) {
        this.openedNodes.pop();
      }
      if (node?.children?.length > 0) {
        this.openedNodes.push(node);
      }
    }
  }
}
