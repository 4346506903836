<ng-container *ngIf="user$ | async as user; else login">
  <div class="user-greeting">
    <span class="user-greeting-text">
      {{ 'cartAdministration.miniLogin.userGreeting' | cxTranslate: { name: user.name } }}
    </span>
    <cx-page-slot position="HeaderLinks"></cx-page-slot>
  </div>
</ng-container>

<ng-template #login>
  <a role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
    'cartAdministration.miniLogin.signInRegister' | cxTranslate
    }}</a>
</ng-template>
