<cx-spinner class="spinner-popover-backdrop" *ngIf="isLoading$ | async"></cx-spinner>
<ng-container *ngIf="cart$ | async as cart">
    <div class="modal-container" [ngClass]="{ 'modal-container-flex-height' : cart.entries?.length < numberOfPositionsToDisplay }">
        <div class="modal-header">
            <span class="modal-header-title">
                {{ 'miniCart.miniCartDialog.title' | cxTranslate }}
            </span>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                <cx-icon [type]="iconType.CLOSE_LIGHT"></cx-icon>
            </button>
        </div>
        <div class="modal-body">
            <div class="outer-container">
                <app-mini-cart-item-list [items]="cart.entries" [fromConfigurator]="cart.fromConfigurator"
                    [maxItemsToShow]="numberOfPositionsToDisplay">
                </app-mini-cart-item-list>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-block btn-primary" type="button" (click)="goToCart()">
                {{ 'miniCart.miniCartDialog.actions.goToCart' | cxTranslate }}
            </button>
        </div>
    </div>
</ng-container>