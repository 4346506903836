import { Component } from '@angular/core';
import { NavigationUIComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-category-navigation-ui',
  templateUrl: './category-navigation-ui.component.html'
})
export class CategoryNavigationUIComponent extends NavigationUIComponent {

}
