<div *ngIf="component.data$ | async as data">
  <h2 class="title" *ngIf="data.title">{{ data.title }}</h2>
  <div
    class="row"
    [style.backgroundColor]="data.textBackgroundColor ?? 'inherit'"
  >
    <div
      class="col-12 col-lg-{{
        data.imageWidthPercentage
          ? round((12 * data.imageWidthPercentage) / 100)
          : '6'
      }}"
    >
      <cx-media [container]="data.medias"></cx-media>
    </div>
    <div
      class="col-12 col-lg-{{
        data.imageWidthPercentage
          ? round(12 - (12 * data.imageWidthPercentage) / 100)
          : '6'
      }}"
      [style.color]="data.color ?? 'inherit'"
    >
      <div class="image-text-content {{ data.styleClasses }}">
        <div [innerHTML]="data.description"></div>
        <div class="image-text-button" *ngIf="data.url.length">
          <app-tile-link
            [url]="data.url"
            [target]="data.openUrlInNewTab === 'true' ? '_blank' : '_self'"
            [classes]="['btn btn-block', data.buttonStyle ?? 'btn-primary']"
          >
            {{ data.buttonText }}
          </app-tile-link>
        </div>
      </div>
    </div>
  </div>
  <hr *ngIf="data.displayBottomLine === 'true'">
</div>
